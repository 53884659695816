const loader = () => {
    return (
        <>
            <div>
                <div className="preloader">
                    <span><i className="lnr lnr-sun"></i></span>
                </div>
            </div>
        </>
    )
}

export default loader;