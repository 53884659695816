import React,{lazy,Suspense} from 'react'
import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom"
import Loader from '../components/common/loader'
const Home  = lazy(()=> import("../components/Home/Home"))
const Blog  = lazy(()=> import('../components/Blog/Blog'))
const Single  = lazy(()=> import('../components/Single/Single'))


function index() {
    const Loading = Loader();
    return (
        <div>
            <Router >
                <Suspense fallback = {Loading} >
                    <Switch>
                        <Route exact path = "/" render = {(props)=> <Home props = {props} />} />
                        <Route exact path = "/data/:path" render = {(props)=> <Home props = {props} />} />
                        <Route exact path = "/blog" render = {(props)=> <Blog props = {props} />} />
                        <Route exact path = "/single" render = {(props)=> <Single props = {props} />} />    
                    </Switch>    
                </Suspense>
            </Router>   
        </div>
    )
}

export default index
