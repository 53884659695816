import Route from "./route"
import 'react-notifications/lib/notifications.css';
function App() {
  return (
    <div className="App">
        <Route />
    </div>
  );
}

export default App;
